<template>
  <div class="mx-6">
    <br/>
    <h2>
      Terms and Conditions
    </h2>
    <br/>
    <p><strong>Last Updated: January 8, 2024</strong></p>
    <br/>
    <p>
      Welcome to https://www.codyeklov.com (the "Site"), the personal and
      professional website of Cody Eklov. These Terms and Conditions outline the
      rules and regulations for the use of the Site. By accessing this website,
      we assume you accept these terms and conditions in full. Do not continue
      to use the Site if you do not accept all of the terms and conditions
      stated on this page.
    </p>

    <h4>1. Intellectual Property Rights</h4>
    <br/>
    <p>
      Unless otherwise stated, Cody Eklov and/or his licensors own the
      intellectual property rights for all material on the Site. All
      intellectual property rights are reserved. You may view and/or print pages
      from https://www.codyeklov.com for your own personal use subject to
      restrictions set in these terms and conditions.
    </p>
    <p>You must not:</p>
    <ul>
      <li>Republish material from https://www.codyeklov.com</li>
      <li>
        Sell, rent, or sub-license material from https://www.codyeklov.com
      </li>
      <li>
        Reproduce, duplicate, or copy material from https://www.codyeklov.com
      </li>
      <li>
        Redistribute content from Cody Eklov's Site (unless content is
        specifically made for redistribution).
      </li>
    </ul>

    <h4>2. User Comments</h4>
    <br/>
    <p>
      Certain parts of this website offer the opportunity for users to post and
      exchange opinions, information, material, and data ('Comments'). Cody
      Eklov does not screen, edit, publish, or review Comments prior to their
      appearance on the website.
    </p>
    <p>
      Comments do not reflect the views or opinions of Cody Eklov, his agents,
      or affiliates. Comments reflect the view and opinion of the person who
      posts such view or opinion.
    </p>
    <p>
      To the extent permitted by applicable laws, Cody Eklov shall not be
      responsible or liable for the Comments or for any loss, liability,
      damages, or expenses caused and or suffered as a result of any use of
      and/or posting of and/or appearance of the Comments on this website.
    </p>

    <h4>3. Hyperlinking to Our Content</h4>
    <br/>
    <p>
      The following organizations may link to our Site without prior written
      approval:
    </p>
    <ul>
      <li>Government agencies;</li>
      <li>Search engines;</li>
      <li>News organizations;</li>
      <li>
        Online directory distributors when they list us in the directory may
        link to our Site in the same manner as they hyperlink to the websites of
        other listed businesses.
      </li>
    </ul>
    <p>
      These organizations may link to our home page, to publications, or to
      other Site information so long as the link: (a) is not in any way
      misleading; (b) does not falsely imply sponsorship, endorsement, or
      approval of the linking party and its products or services; and (c) fits
      within the context of the linking party's site.
    </p>

    <h4>4. Content Liability</h4>
    <br/>
    <p>
      We shall have no responsibility or liability for any content appearing on
      your website. You agree to indemnify and defend us against all claims
      arising out of or based upon your website.
    </p>

    <h4>5. Reservation of Rights</h4>
    <br/>
    <p>
      We reserve the right at any time and in our sole discretion to request
      that you remove all links or any particular link to our Site. You agree to
      immediately remove all links to our Site upon such request.
    </p>

    <h4>6. Disclaimer</h4>
    <br/>
    <p>
      The information on this website is provided "as is," with all faults, and
      Cody Eklov makes no express or implied representations or warranties, of
      any kind related to this website or the materials contained on this
      website.
    </p>
    <p>
      Additionally, nothing contained on this website shall be construed as
      providing consult or advice to you.
    </p>

    <h4>7. Governing Law &amp; Jurisdiction</h4>
    <br/>
    <p>
      These terms and conditions are governed by and construed in accordance
      with the laws of Iowa and you irrevocably submit to the exclusive
      jurisdiction of the courts in that State or location.
    </p>

    <h4>Contact Information</h4>
    <br/>
    <p>
      If you have any queries regarding any of our terms, please contact us at
      info@codyeklov.com or by mail at 402 Orchard Hills Dr., Norwalk, IA 50211.
    </p>
    <br/>
  </div>
</template>