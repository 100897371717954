import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import TermsConditions from "../views/TermsConditions.vue";
import PrivacyPolicy from "../views/PrivacyPolicy";

const routes = [
    {
      path: "/",
      name: "home",
      component: Home,
      alias: ["/main", "/home", "/index.html", "/index.htm"],
    },
    {
      path: "/terms-conditions/",
      name: "terms-conditions",
      component: TermsConditions,
    },
    {
      path: "/privacy-policy/",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
  ];
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      return { top: 0 }
    },
  });
  
  export default router;
  