<template>
    <header-component></header-component>
    <landing-component></landing-component>
    <services-component></services-component>
    <technology-component></technology-component>
    <links-component></links-component>
    <contact-component></contact-component>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
import LandingComponent from '../components/LandingComponent.vue'
import ServicesComponent from '../components/ServicesComponent.vue'
import TechnologyComponent from '../components/TechnologyComponent.vue'
import ContactComponent from '../components/ContactComponent.vue'
import LinksComponent from '../components/LinksComponent.vue'

export default {
    name: "home",
    components: {
        HeaderComponent,
        LandingComponent,
        ServicesComponent,
        TechnologyComponent,
        ContactComponent,
        LinksComponent,
    },
    setup() {
        
    },
};
</script>
