<template>
  <section id="footer" class="lower-section bg-zinc-800 pb-2 text-zinc-200">
    <div class="flex flex-row justify-center my-3">
      <router-link :to="{ name: 'privacy-policy'}" class="mx-6">
        Privacy Policy
      </router-link>
      <router-link :to="{ name: 'terms-conditions'}" class="mx-6">
        Terms &amp; Conditions
      </router-link>
    </div>
    <div class="my-3">
      <router-link :to="{ name: 'home'}">
        &copy; Cody Eklov {{ new Date().getFullYear() }}
      </router-link>
    </div>
  </section>
</template>

<style scoped>
</style>